import $ from 'jquery';

$.addInit(function(context) {
	var base = '.b-form';
	$(base, context).validity({
		errors: {
			typeMismatch: 'Некорректное значение',
			valueMissing: 'Заполните поле'
		},
		unknownError: 'Неизвестная ошибка',
		output: function(input, message) {
			var field = $(input).not('button, [type=submit]').closest(base + '-field');
			field.toggleClass('m-error', !!message);
			field.find('.i-form-checkbox').toggleClass('m-error', !!message);
			field.find(base + '-error').text(message);
		}
	});
});
