import $ from 'jquery';
import breakpoint from '../scripts/breakpoint';

$.addInit(function(context){
	var base = '.b-products';
	$(base, context).each(function(){
		var component = $(this);

		var photo = component.find(base + '-photo');
		var links = component.find(base + '-link');
		var pictures = component.find(base + '-picture');
		var img = pictures.find('img');

		links.on('mouseenter', function(e) {
			var $el = $(this);

			photo.addClass('m-hide');

			$el.addClass('m-active');
			component.addClass('m-active');

			
			setTimeout(function() {
				photo.removeClass('m-hide');
				img.attr('src', $el.data('src'));
			}, 800)
		});

		links.on('mouseleave', function(e) {
			var $el = $(this);

			$el.removeClass('m-active');
			component.removeClass('m-active');
		});
	});
});