import $ from 'jquery';

$.addInit(function(context){
	var base = '.b-animTitle';
	$(base, context).each(function(){
		var showTitle = function(entries, observer) {
			entries.forEach(function(entry) {
				if(entry.isIntersecting) {
					setTimeout(function() {
						entry.target.classList.add('m-active');
					}, 300);
				}
			});
		}

		const options = {
			threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1]
		};

		var observer = new IntersectionObserver(showTitle, options);

		var boxes = document.querySelectorAll(base);
		boxes.forEach(function(box) {
			observer.observe(box);
		});
	});
});