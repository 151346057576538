import $ from 'jquery';
import bodyScroll from '../scripts/bodyScroll';
import breakpoint from '../scripts/breakpoint';
import shared from '../scripts/shared';

$.addInit(function(context){
	var base = '.b-head';
	$(base, context).each(function(){
		var component = $(this);
		var humburger = component.find('.b-humburger');
		var menuWrap = component.find(base + '-nav');
		var menu = component.find('.b-menu');
		var isActive = false;

		function toggleMenu() {
			isActive = !isActive;

			humburger.toggleClass('m-active');
			component.toggleClass('m-active');

			if (isActive) {
				bodyScroll.disable(menu.get(0), {reserveScrollBarGap: true});
			} else {
				bodyScroll.enable(menu.get(0));
			}
		}

		breakpoint(null, 'lg')
			.onmatch(function(){
				humburger.on('click', toggleMenu);
			})
			.onmismatch(function() {
				humburger.off('click', toggleMenu);
			});

		shared.headerHeight = component.height();


		var info = component.find(base + '-info');
		var container = component.find(base + '-container');

		breakpoint('xs', 'lg')
			.onmatch(function(){
				info.insertBefore(menu);
			})
			.onmismatch(function(){
				info.appendTo(container);
			});

		var invertClass = 'b-head_invert';

		function removeInvertOnScroll() {
			var currentScrollPos = $(this).scrollTop();

			if (currentScrollPos > 0) {
				component.removeClass(invertClass);
			} else {
				component.addClass(invertClass);
			}
		}
		
		if (component.hasClass(invertClass)) {
			$(window).on('scroll', removeInvertOnScroll);
		}
	});
});
