import theme from '../theme.json';

var observers = {};

function ltUnit(value) {
	var match = String(value).match(/^(\d+)(\w*)$/);
	return match && parseFloat(match[1]) - 0.02 + match[2];
}

function getMedia(min, max, media) {
	var mediaList = [];

	media && mediaList.push(media);
	min && mediaList.push('(min-width: ' + theme.breakpoints[min] + ')');
	max && mediaList.push('(max-width: ' + ltUnit(theme.breakpoints[max]) + ')');

	return mediaList.join(' and ');
}

function MediaObserver(media) {
	this.mediaQuery = matchMedia(media);
}
MediaObserver.prototype.onchange = function(cb) {
	this.mediaQuery.addListener(cb);
	cb(this.mediaQuery);
	return this;
};
MediaObserver.prototype.onmatch = function(cb) {
	this.mediaQuery.addListener(function(e) {
		e.matches && cb(e)
	});
	this.mediaQuery.matches && cb(this.mediaQuery);
	return this;
};
MediaObserver.prototype.onmismatch = function(cb) {
	this.mediaQuery.addListener(function(e) {
		!e.matches && cb(e)
	});
	!this.mediaQuery.matches && cb(this.mediaQuery);
	return this;
};

function breakpoint(min, max, media) {
	var key = [min, max, media].join('-');

	if (!observers[key]) {
		observers[key] = new MediaObserver(getMedia(min, max, media));
	}

	return observers[key];
};

export default breakpoint;