import $ from 'jquery';
import Swiper from '../../node_modules/swiper/js/swiper.min.js';

$.addInit(function(context){
	var base = '.b-medicationsSlider';
	$(base, context).each(function(i, el){
		setTimeout(function() {
			var slider = new Swiper (el.querySelector('.b-medicationsSlider-container'), {
				slidesPerView: 'auto',
				spaceBetween: 0,
				slideClass: 'b-cardMedications',
				// breakpoints: {
				// 	768: {
				// 		spaceBetween: 80,
				// 	},
				// },
				navigation : {
					nextEl : el.querySelector('.b-sliderArrows-btn_next'),
					prevEl : el.querySelector('.b-sliderArrows-btn_prev')
				},
			});
		});
	});
});