import $ from 'jquery';
import Swiper from '../../node_modules/swiper/js/swiper.min.js';

$.addInit(function(context){
	var base = '.b-newsSlider';
	$(base, context).each(function(i, el){
		setTimeout(function() {
			var slider = new Swiper (el.querySelector('.b-newsSlider-container'), {
				slidesPerView: 'auto',
				spaceBetween: 0,
				slideClass: 'b-cardNews',
				// breakpoints: {
				// 	1024: {
				// 		slidesPerView: 3,
				// 	},
				// 	1380: {
				// 		slidesPerView: 4,
				// 	},
				// 	1680: {
				// 		slidesPerView: 4,
				// 	},
				// },
				navigation : {
					nextEl : el.querySelector('.b-sliderArrows-btn_next'),
					prevEl : el.querySelector('.b-sliderArrows-btn_prev')
				},
			});
		});
	});
});