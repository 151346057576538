import $ from 'jquery';
import Swiper from '../../node_modules/swiper/js/swiper.min.js';

$.addInit(function(context){
	var base = '.b-partnerSlider';
	$(base, context).each(function(i, el){
		setTimeout(function() {
			var slider = new Swiper (el.querySelector('.b-partnerSlider-container'), {
				slidesPerView: 'auto',
				spaceBetween: 16,
				slideClass: 'b-partnerSlider-item',
				breakpoints: {
					768: {
						spaceBetween: 80,
					},
					1024: {
						slidesPerView: 6,
						spaceBetween: 8,
					},
					1520: {
						slidesPerView: 6,
						spaceBetween: 8,
					},
				},
			});
		});
	});
});