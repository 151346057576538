import breakpoint from './breakpoint';
import init from './jquery.init';
import validity from './jquery.validity';
import './jquery.transitionDuration';
import $ from 'jquery';
import { showModal } from '../components/b-modal';

window.jQuery = window.$ = $;

$.showModal = showModal;

function updateScrollbarWidth() {
	var root = document.documentElement;
	root.style.setProperty('--window-scrollbar-width', (window.innerWidth - root.clientWidth) + 'px');
}
window.addEventListener('resize', updateScrollbarWidth);
$(updateScrollbarWidth);

// full height for webkit
function updateVh() {
	var vh = window.innerHeight * 0.01;
	document.documentElement.style.setProperty('--vh', "".concat(vh, "px"));
}

window.addEventListener('resize', updateVh);
updateVh();
