import $ from 'jquery';
import breakpoint from '../scripts/breakpoint';

$.addInit(function(context) {
	var base = '.b-hero';
	$(base, context).each(function(){
		var component = $(this);

		component.addClass('m-animate');

		var slides = component.find(base + '-slide');
		var indicators = $();

		var activeIndex = 0;
		var interval = +component.data('interval') || 5000;
		var timeout = null;

		function setActive(index, clear) {
			index = (index + slides.length) % slides.length;

			if (interval) {
				clearTimeout(timeout);

				if (!clear) {
					timeout = setTimeout(setActive.bind(this, index + 1), interval);
				}
			}

			slides.eq(activeIndex).add(indicators.eq(activeIndex)).removeClass('m-active');
			slides.eq(index).add(indicators.eq(index)).addClass('m-active');

			activeIndex = index;
		}

		var sliderWrap = component.find('.i-hero-slider');

		sliderWrap.on('mouseenter', function() {
			setActive(activeIndex);
			$(this).siblings(base + '-link').addClass('m-hideTitle');
		});

		sliderWrap.on('mouseleave', function() {
			setActive(0, true);
			slides.removeClass('m-active');
			$(this).siblings(base + '-link').removeClass('m-hideTitle');
		});

		var video = component.find(base + '-video');
		var videoWrap = component.find('.i-hero-video');

		videoWrap.on('mouseenter', function() {
			video.get(0).play();
			video.addClass('m-active');
			$(this).siblings(base + '-link').addClass('m-hideTitle');
		});

		videoWrap.on('mouseleave', function() {
			video.get(0).pause();
			video.get(0).currentTime = 0;
			video.removeClass('m-active');
			$(this).siblings(base + '-link').removeClass('m-hideTitle');
		});

		var links = component.find(base + '-link');

		function isTouchDevice() {
			if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
				links.one('click', false);
			} else {
				links.off('click');
			}
		}

		breakpoint(null, 'lg')
			.onmatch(function(){
				})
				.onmismatch(function() {
					isTouchDevice();
				});
	});
});
