import $ from 'jquery';

export function getVideoIframeUrl (url) {
	// - Supported YouTube URL formats:
	//   - http://www.youtube.com/watch?v=My2FRPA3Gf8
	//   - http://youtu.be/My2FRPA3Gf8
	//   - https://youtube.googleapis.com/v/My2FRPA3Gf8
	// - Supported Vimeo URL formats:
	//   - http://vimeo.com/25451551
	//   - http://player.vimeo.com/video/25451551
	// - Also supports relative URLs:
	//   - //player.vimeo.com/video/25451551

	var match = url.match(/(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\&\S+)?/);

	// console.log(match)

	if (RegExp.$3.indexOf('youtu') > -1) {
		return 'https://www.youtube.com/embed/' + match[6] + '?autoplay=1&enablejsapi=1';
	} else if (RegExp.$3.indexOf('vimeo') > -1) {
		return 'https://player.vimeo.com/video/' + match[6] + '?autoplay=1';
	}
}

$.addInit(function(context) {
	var base = '.i-showVideo';
	$(base, context).each(function(){
		var component = $(this);

		component.on('click', function(e) {
			e.preventDefault();

			if (component.find('iframe').length)
				return;

			var url = component.data('video-url');

			component.replaceWith('<iframe id="' + component.data('video-url') + '" type="text/html" src="' + getVideoIframeUrl(url) + '" frameborder="0" allowfullscreen="allowfullscreen"></iframe>');
		});
	});
});
