import $ from 'jquery';

var init = window.$init || [];

$.addInit = function(fn) {
	init.push(fn);
	if ($.isReady) {
		fn.apply(document, [document, $]);
	}
};

$.fn.execInit = function() {
	return this.each(function() {
		var context = this;
		init.forEach(function(fn) {
			return fn.apply(context, [context, $]);
		});
	});
};

$(function() {
	$(document).execInit();
});

export default $.addInit;