import $ from 'jquery';

$.addInit(function(context){
	var base = '.b-control';
	$(base, context).each(function(){
		var component = $(this);
		var input = component.find(base + '-input');
		var indicator = component.find(base + '-indicator');
		var text = component.find(base + '-text');

		input.change(function(e){
			indicator.attr('data-value', this.value.replace('C:\\fakepath\\', ''));
		});
	});
});
