import $ from 'jquery';
import breakpoint from '../scripts/breakpoint';

$.addInit(function(context){
	var base = '.i-menu';
	$(base, context).each(function(){
		var component = $(this);
		var head = component.closest('.b-head');

		var elements = {
			menu: '.i-menu-menu',
		};

		var refs = {
			menuWrap: component,
			rootMenu: component.children(base + '-menu'),
			sub: component.find(base + '-sub'),
			backBtn: component.find(base + '-linkBack'),
			link: component.find(base + '-linkDropdown'),
			items: component.closest('.b-head-nav'),
			ptMenuWrap: parseInt(component.css('padding-top')),
			pbMenuWrap: parseInt(component.css('padding-bottom')),
		}

		var currentMenu = refs.rootMenu;
		var currentLevel = 0;

		function getPrevMenu() {
			return currentMenu.parent().closest(elements.menu);
		}

		function setLevel(level) {
			refs.rootMenu.css('left', -100 * level + '%');
			currentLevel = level;
		}

		function setMenu(menu) {
			var level = menu.parents(elements.menu).length;

			menu.show();

			refs.menuWrap.css('height', menu.height() + refs.ptMenuWrap + refs.pbMenuWrap);
			refs.items.toggleClass('m-openSub', level > 0);

			menu.find(elements.menu).hide();

			if (level > 0) {
				menu.css({
					top: component.prop('scrollTop') + 'px',
				});

				menu.addClass('m-sub');
			}

			setLevel(level);
			currentMenu = menu;
		}

		function linkHandler(e) {
			e.preventDefault();
			var findMenu = $(this).siblings(elements.menu);
			setMenu(findMenu);
		}

		function backHandler(e) {
			e.preventDefault();
			setMenu(getPrevMenu());
		}

		var isTouch = 'ontouchstart' in window;

		function isTouchDevice() {
			if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
				refs.link.one('click', false);
			} else {
				refs.link.off('click');
			}
		}

		function attach() {
			refs.link.on('click', linkHandler);
			refs.backBtn.on('click', backHandler);

			setTimeout(function() {
				setMenu(refs.rootMenu);
			}, 0);
		}

		function deattach() {
			refs.link.off('click', linkHandler);
			refs.backBtn.off('click', backHandler);
		}

		function setHeight() {
			refs.sub.each(function(i, el) {
				var $el = $(el);
				$el.height('auto');
				var maxHeight = $el.outerHeight();
				var height = $el.height();

				if (maxHeight + component.height() < $(window).height() ) {
					$el.height(height);
				} else {
					$el.css({
						height: 'calc(100vh - 148px)'
					});
				}
			});
		}

		function unSetHeight() {
			refs.sub.height('auto');
		}

		breakpoint(null, 'lg')
			.onmatch(function(){
					attach();

					unSetHeight();
					$(window).off('resize', setHeight);
				})
				.onmismatch(function() {
					deattach();

					isTouchDevice();

					$(elements.menu).removeAttr('style');
					component.removeAttr('style');

					refs.rootMenu.on('mousemove', function() {
						head.addClass('m-hover');
					});
					refs.rootMenu.on('mouseleave', function() {
						head.removeClass('m-hover');
					});

					setHeight();
					$(window).on('resize', setHeight);
				});
	});
});
