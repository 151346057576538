import $ from 'jquery';

$.fn.transitionDuration = function(cb){
	var value = (parseFloat(this.css('transitionDuration')) + parseFloat(this.css('transitionDelay'))) * 1000;

	if (cb) {
		setTimeout(cb, value);
		return this;
	} else {
		return value;
	}
};