import $ from 'jquery';

import { showModal } from './b-modal';
import { getVideoIframeUrl } from './i-showVideo';

var videoPreiviewTemplate = ' \
	<div class="b-videoPreview "> \
		<div class="b-videoPreview-container"> \
			<div class="g-ratio g-ratio_cover g-ratio_16:9"> \
				<img src=""> \
			</div> \
			<a href="#" class="b-videoPreview-play i-showVideo" data-video-url=""> \
				<span class="b-videoPreview-icon"></span> \
			</a> \
		</div> \
	</div> \
';
var modalTemplate = '\
	<div class="b-modal b-modal_video">\
		<div class="b-modal-wrap">\
			<span class="b-modal-close i-modal-close">\
				<span class="b-icon b-icon_xModal "></span>\
			</span>\
			<div class="b-modal-dialog" id="media-video">\
				' + videoPreiviewTemplate + '\
			</div>\
		</div>\
	</div>\
';

var modal;
function getModal() {
	if (!modal) {
		var wrap = $('<div>');
		modal = $(modalTemplate).appendTo(wrap);
		wrap.execInit();
		modal.appendTo(document.body);
	}
	return modal;
}

$.addInit(function(context) {
	if (context === document) {
		$(document).on('click', '.i-modal-video', function(e) {
			e.preventDefault();
			var modal = getModal();
			var link = $(this);
			var videoId = link.data('video-url');
			modal.find('img').prop('src', this.href);
			modal.find('.b-videoPreview-play').data('video-url', videoId);
			modal.find('iframe').prop('src', getVideoIframeUrl(videoId));
			var videoPreiview = modal.find('.b-videoPreview');
			showModal(modal);

			modal.on('close', function() {
				var iframe = modal.find('iframe')[0];
				if (iframe !== undefined) {
					iframe.contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*');
				}
				videoPreiview.html(videoPreiviewTemplate);
				videoPreiview.execInit()
			});
		});
	}
});
