import $ from 'jquery';

$.addInit(function(context){
	var base = '.b-tabs';
	$(base, context).each(function(){
		var component = $(this);
		var tabs = component.find(base + '-tab');
		var panels = component.find(base + '-panel');

		function setActive(tab) {
			var hash = $(tab).prop('hash');
			var panel = hash ? panels.filter(hash) : panels.eq(tabs.index(tab));
			panel.add(tab).addClass('m-active');
			panels.not(panel).add(tabs.not(tab)).removeClass('m-active');
		}

		function setActiveByHash(hash) {
			if (hash) {
				var tab = tabs.filter('[href="' + hash + '"]:first');
				if (tab.length) {
					setActive(tab);
					return true;
				}
			}
			return false;
		}

		if (!setActiveByHash(location.hash)) {
			setActive(tabs.first());
		}

		tabs.click(function(e){
			e.preventDefault();
			setActive(this);
			this.hash && history.replaceState({}, '', this.hash);
		});

		panels.on('show', function(e) {
			if (this.id) {
				var hash = '#' + this.id;
				setActiveByHash(hash);
				hash && history.replaceState({}, '', hash);
			} else {
				setActive(tabs.eq(panels.index(this)));
			}
		});
	});
});
