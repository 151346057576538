import $ from 'jquery';

$.validity = {
	defaults: {
		errors: {
			badInput: 'Bad input',
			customError: 'Custom error',
			patternMismatch: 'Pattern mismatch',
			rangeOverflow: 'Range overflow',
			rangeUnderflow: 'Range underflow',
			stepMismatch: 'Step mismatch',
			tooLong: 'Too long',
			tooShort: 'Too short',
			typeMismatch: 'Type mismatch',
			valueMissing: 'Value missing'
		},
		unknownError: 'Unknown error',
		output: function(input, message) {
			console.log('validity.output', input, message);
		},
		getMessage: function(input){
			if (input.validity.valid)
				return '';

			for (var error in this.errors) {
				if (input.validity[error])
					return this.errors[error];
			}

			return this.unknownError;
		}
	}
};

$.fn.validity = function(options) {
	options = $.extend({}, $.validity.defaults, options);

	return this.each(function(){
		var component = $(this);
		var form = component.closest('form');
		var elements = $(form.prop('elements'));

		var tel = form.find('input[type="tel"]');
		tel.prop('pattern', '[-+() \\d]*');

		setTimeout(function() {
			elements.on('change input', function(){
				options.output(this, options.getMessage(this));
			});
		});

		form.on('submit', function(e){
			if (this.checkValidity())
				return;

			e.preventDefault();
			e.stopPropagation();

			elements.trigger('input');
		});

		form.attr('novalidate', true);
	});
}

export default $.validity;
