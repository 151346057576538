import $ from 'jquery';

$.addInit(function(context){
	var base = '.i-playVideo';
	$(base, context).each(function(){
		var component = $(this);
		var video = component.parent().find('video').get(0);

		component.on('click', function(e) {
			e.preventDefault();

			if (video.paused) {
				video.play();
				component.hide();
			} else {
				video.pause();
				component.show();
			}
		});

		$('[id^="media"]').closest('.b-modal').on('close', function() {
			$(this).find('video').get(0).pause();
		});
	});
});