import $ from 'jquery';
import shared from '../scripts/shared';

$.addInit(function(context){
	var base = '.i-scroll';

	$(base, context).each(function(){
		var component = $(this);
		var offset = shared.headerHeight + shared.scrollOffset;

		component.on('click', function(e){
			e.preventDefault();
			var target = $(this).attr('href');

			$(target).trigger('show');

			$('html,body').animate({ scrollTop: $(target).offset().top - offset}, 1000);
		});
	});
});
